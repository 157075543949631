import React from "react";
import { Link } from "gatsby"


const Pinned = ({ posts }) => {

    return (
        <div className="flex mt-1 mb-4" style={{ listStyle: `none` }}>
            {posts.map(post => (
                (() => {
                    if (post.frontmatter.pinned) {
                        return (
                            <Link className="mr-2" to={post.fields.slug} itemProp="url">
                                <li className="p-2 rounded border border-gray-default hover:bg-gray-default" key={post.fields.slug}>
                                    <article
                                        className="flex px-4"
                                        itemScope
                                        itemType="http://schema.org/Article"
                                    >
                                        <h3 className="text-base md:text-sm text-blue-default font-bold">
                                            {post.frontmatter.title}
                                        </h3>
                                    </article>
                                </li>
                            </Link>
                        );
                    }
                })()
            ))}
        </div>
    )

}

export default Pinned
